/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { func, bool } from 'prop-types';
import { Carousel, Image } from '@thd-olt-component-react/core-ui';
import { Price } from '@thd-olt-component-react/price';

import { useDrawer } from '@thd-olt-component-react/drawer';
import { Sharebelt, ShareFavorite } from '@thd-olt-component-react/thd-sharebelt';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { useStoreId } from '@thd-nucleus/experience-context';
import { QueryProvider } from '@thd-nucleus/data-sources';
import './QuickViewDetails.scss';
import { sanitizeImageUrl } from '../../utils/QuickViewUtils';
import { QuickViewRatings } from '../quick-view-ratings/QuickViewRatings';
import { QuickViewMore } from './QuickViewMore';
import { QuickViewFooter } from '../quick-view-footer/QuickViewFooter';
import { QuickViewContext } from '../../utils/QuickViewProvider';
import { QuickViewNav } from '../quick-view-nav/QuickViewNav';
import { ProductBadge } from './product-badge/ProductBadge';

function renderQuickViewDetails({
  showBackNavLink,
  product,
  brand,
  label,
  productReviewEvents,
  itemId,
  images,
  paginateCarousel,
  storeId,
  setCurrentDrawer
}) {
  return (
    <section className="quickview-details">
      <QuickViewNav
        showBackNavLink={showBackNavLink}
        closeOnBackNavLink
      />
      <div className="quickview-details__desc">
        <h1 className="u__bold">
          <ProductBadge badges={product?.badges} />
          {brand}
        </h1>
        <p className="quickview-details__label">{label}</p>
        <div
          role="link"
          tabIndex="0"
          data-testid="quickview-more-link"
          {...productReviewEvents}
        >
          <QuickViewRatings />
        </div>
        {product && (
          <Sharebelt itemId={itemId}>
            <ShareFavorite showCircle showFavoritesCount />
          </Sharebelt>
        )}
      </div>
      {images.length > 0 && (
        <div className="quickview-details-carousel">
          <Carousel
            breakpoints={{
              sm: {
                slidesPerView: 1,
                slidesPerGroup: 1
              },
              md: {
                slidesPerView: 1,
                slidesPerGroup: 1
              },
              lg: {
                slidesPerView: 1,
                slidesPerGroup: 1
              },
              xl: {
                slidesPerView: 1,
                slidesPerGroup: 1
              }
            }}
            disableFocus
            pagination={paginateCarousel}
            peek={false}
            slidesPerGroup={1}
            slidesPerView={1}
          >
            {images.map((image) => (
              <div
                className="quickview-details-carousel__image-wrapper"
                key={`quickview-details-carousel-image-${image}`}
              >
                <Image
                  alt={image.type}
                  src={sanitizeImageUrl(image.url)}
                  lazy
                  width={1}
                  height={1}
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
      <div className="sui-p-2">
        <Price
          itemId={itemId}
          storeId={storeId}
        />
      </div>
      <div className="quickview-details__kpf sui-p-2">
        <QueryProvider
          defaultVariables={{ storeId }}
          cacheKey="quickview-keyproductfeatures"
        >
          <KeyProductFeatures
            hideImage
            itemId={itemId}
            storeId={storeId}
            maxfeatures={4}
            twoColumnGrid
          />

        </QueryProvider>

      </div>
      <div className="quickview-details__other">
        <QuickViewMore
          title="Product Overview"
          onClick={() => setCurrentDrawer('quick-view-product-overview')}
        />
        <QuickViewMore
          title="Specifications"
          onClick={() => setCurrentDrawer('quick-view-product-specs')}
        />
        <QuickViewMore title="Ratings" {...productReviewEvents}>
          <div
            className="quickview-details__other-ratings"
          >
            <h3>Ratings</h3>
            <div
              className="quickview-details__other-ratingstar"
            >
              <QuickViewRatings />
            </div>
          </div>
        </QuickViewMore>
      </div>
      <QuickViewFooter />

    </section>
  );
}

export const QuickViewDetails = ({
  setLoadRatingsnReviews,
  showBackNavLink,
  paginateCarousel
}) => {
  const { product } = useContext(QuickViewContext);
  const storeId = useStoreId();
  const { setCurrentDrawer } = useDrawer();

  const brand = product?.identifiers?.brandName;
  const label = product?.identifiers?.productLabel;
  const images = product?.media?.images || [];
  const itemId = product?.itemId;
  const productReviewEvents = {
    onClick: () => setCurrentDrawer('quick-view-product-reviews'),
    onMouseEnter: () => setLoadRatingsnReviews(true)
  };

  return renderQuickViewDetails({
    showBackNavLink,
    product,
    brand,
    label,
    productReviewEvents,
    itemId,
    images,
    paginateCarousel,
    storeId,
    setCurrentDrawer
  });
};

QuickViewDetails.displayName = 'QuickViewDetails';

QuickViewDetails.propTypes = {
  setLoadRatingsnReviews: func.isRequired,
  showBackNavLink: bool.isRequired,
  paginateCarousel: bool,
};

QuickViewDetails.defaultProps = {
  paginateCarousel: true
};
